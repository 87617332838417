@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
:root{
    --bleu-nuit: #00004D;
    --or: #D7C378;
    --corail: #EB585B;
    --bleu-olympique: #0282C8;
    --jaune-olympique: #FABE34;
    --vert-olympique: #00A651;
    --rouge-olympique: #ED354F;
    --noir: #000000;
    --blanc: #F3F3F3;
}
*{
  background-color: var(--blanc);
  font-family: 'Poppins', sans-serif;
}
.home{
    height: calc(100vh - 143px);
    width: 100%;
    display: grid;
    grid-template-areas: "home-sub-1 home-sub-2 home-sub-3 home-sub-4"
                         "home-sub-5 home-sub-6 home-sub-3 home-sub-4"
                         "home-sub-7 home-sub-8 home-sub-11 home-sub-11"
                         "home-sub-9 home-sub-10 home-sub-12 home-sub-12";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
}
.home-sub-1{
    grid-area: home-sub-1;
    display: flex;
    align-items: start;
    justify-content: end;
    font-weight: bold;
    font-size: 8em;
}
.home-sub-1 > span{
    color: var(--bleu-nuit);
    display: flex;
    height: 80%;
    width: 95%;
}
.home-sub-2{
    grid-area: home-sub-2;
    background-color: var(--bleu-nuit);
    display: flex;
    align-items: start;
    justify-content: end;
    font-weight: bold;
    font-size: 8em;
}
.home-sub-2 > span{
    background-color: var(--bleu-nuit);
    color: var(--blanc);
    display: flex;
    height: 80%;
    width: 95%;
}
.home-sub-3{
    grid-area: home-sub-3;
    background-color: var(--corail)
}
.home-sub-4{
    grid-area: home-sub-4;
    background-image: url("../public/img/Logo_JO_d'été_-_Paris_2024.svg.png");
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
}
.home-sub-5{
    grid-area: home-sub-5;
    background-color: var(--bleu-nuit);
    display: flex;
    align-items: center;
    justify-content: end;
    font-weight: bold;
    font-size: 4em;
}
.home-sub-5 > span{
    background-color: var(--bleu-nuit);
    color: var(--blanc);
    width: 50%;
    text-align: end;
}
.home-sub-6{
    grid-area: home-sub-6;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.home-sub-6 > div{
    display: flex;
    flex-basis: 100%;
    align-items: center;
}
.home-sub-5-first-input{
    justify-content: flex-start;
}
.home-sub-5-first-input > input{
    transform: translateX(10px);
}
input[type=date]{
    border: none;
    color: var(--bleu-nuit);
    font-weight: bold;
    font-size: 2em;
}
.home-sub-7{
    grid-area: home-sub-7;
    background-color: var(--corail)
}
.home-sub-8{
    grid-area: home-sub-8;
}
.home-sub-9{
    grid-area: home-sub-9;
    background-color: var(--corail);
    display: flex;
    align-items: start;
    justify-content: end;
    font-weight: bold;
    font-size: 7em;
}
.home-sub-9 > span{
    display: flex;
    background-color: var(--corail);
    color: var(--blanc);
    height: 80%;
    width: 95%;
}
.home-sub-10{
    grid-area: home-sub-10;
    background-image: url("../public/img/Flèche OLYMTRIP.png");
    background-position: right;
    background-size: 120%;
}
.home-sub-11{
    grid-area: home-sub-11;
    background-color: var(--bleu-nuit)
}
.home-sub-11 > div{
    background-color: transparent;
}
.home-sub-11 > div > span{
    background-color: transparent;
    color: var(--blanc);
    font-size: 2em;
    font-weight: bold;
}
.home-sub-11 > div > select{
    width: 50%;
}
.home-sub-12{
    grid-area: home-sub-12;
    background-color: var(--bleu-nuit);
    display: flex;
    align-items: center;
}
.home-sub-12 > div{
    width: 55%;
    height: 50%;
    background-color: var(--bleu-nuit);
}
select{
    border: none;
    width: 80%;
    background-color: transparent;
    color: var(--blanc);
    font-weight: bold;
    font-size: 3em;
}
select > option{
    background-color: var(--blanc);
    color: var(--bleu-nuit);
}
.border{
    border: 1px black solid;
}

.eventwidget1 {

  height: calc((100vh - 96px)/4);
  width: 100%;
  display: grid;
  grid-template-areas: "widget-part1 widget-part2";
  grid-template-columns: repeat(4, 1fr);
  border: 1px #000000;
  margin-top: 1000px;

}

.eventwidget2 {
  height: calc((100vh - 96px)/4);
  width: 100%;
  display: grid;
  grid-template-areas: "widget-part3 widget-part1";
  grid-template-columns: repeat(4, 1fr);
  border: 1px #000000;
  margin-top: 1000px;
}

.widget-part1 {
  grid-area: 1 / 1 / 1 / 1;
  background-color: white;
  border: 1px #000000;
}

.widget-part2 {
  grid-area: 1 / 2 / 1 / 5;
  background-color: var(--bleu-nuit);
  border: 1px #000000;
}

.widget-part3 {
  grid-area: 1 / 1 / 1 / 4;
  background-color: var(--corail);
  border: 1px #000000;
}

.widget-part4 {
  grid-area: 1 / 4 / 1 / 4;
  background-color: white;
  border: 1px #000000;
}

.eventtitle {
  margin-top: 80px;
  margin-bottom: 40px;
  margin-left: 20px;
}

.eventwidget1 p {
    font-weight: 1000;
    margin-top: 20px;
    background: transparent;
    color:var(--blanc);
}
.eventwidget2 p {
    font-weight: 1000;
    margin-top: 20px;
    background: transparent;
    color:var(--blanc);

}
.search-button{
    background-color: var(--bleu-nuit);
    border-color: var(--bleu-nuit);
}
a {
    text-decoration: none;
}